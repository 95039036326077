import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { talentLocales } from '@core/constants';
import { LayoutService } from '@core/services/layout.service';
import { PostHogService } from '@core/services/posthog.service';
import { TALENT_TRANSLOCO_HEADER_FROM_ROUTE } from '@models/routes.model';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  private _translocoReady$$ = new BehaviorSubject<boolean>(false);
  private _translocoHeaderBuffer$$ = new BehaviorSubject<string | undefined>(
    undefined,
  );

  constructor(
    private _transloco: TranslocoService,
    @Inject(DOCUMENT) private _document: Document,
    private _router: Router,
    private _layoutService: LayoutService,
    private readonly postHogService: PostHogService,
  ) {
    /**
     * Sets navbar header if transloco service is ready.
     * Otherwise, header is stored in a buffer in order to wait
     * transloco to be ready, then sets the header.
     *
     * This logic is needed because landing on a precise page could
     * sets a header BEFORE transloco is ready
     */
    this._setHeaderFromRoute$().subscribe();
    this._listenToTranslocoReadyStateAndConsumeHeaderBuffer$().subscribe();
  }

  ngOnInit() {
    const localeString = talentLocales.join('|');
    const regex = `/${localeString}/`;

    const browserLang = getBrowserLang().match(regex) ? getBrowserLang() : 'fr';

    // set default document language, the proper lang
    // is set when the bootstrap data is received (see auth effects)
    this._document.documentElement.lang = browserLang;

    this._transloco.setDefaultLang(browserLang);
    this._transloco.setActiveLang(browserLang);

    this.postHogService.emitAccessEvent$$.next();
  }

  /**
   * Sets navbar header if transloco service is ready.
   * Otherwise, header is stored in a buffer in order to wait
   * transloco to be ready
   */
  private _setHeaderFromRoute$() {
    return combineLatest([this._router.events, this._translocoReady$$]).pipe(
      filter(
        ([routerEvent, _translocoEvent]) =>
          routerEvent instanceof NavigationStart,
      ),
      tap(([routerEvent, _translocoEvent]) => {
        const routerUrl = (routerEvent as NavigationStart).url;
        // If no url from router, no need to continue
        if (!routerUrl) return;

        const translocoHeader = TALENT_TRANSLOCO_HEADER_FROM_ROUTE[routerUrl];

        if (!this._translocoReady$$.value) {
          this._translocoHeaderBuffer$$.next(translocoHeader);
          return;
        }
        const headerLabel = translocoHeader
          ? this._transloco.translate(translocoHeader)
          : undefined;
        this._layoutService.headerLabel$$.next(headerLabel);
      }),
    );
  }

  /**
   * Gets transloco service ready state.
   * If a header is stored in a buffer, it sets the header too.
   */
  private _listenToTranslocoReadyStateAndConsumeHeaderBuffer$() {
    return combineLatest([
      this._transloco.events$,
      this._translocoHeaderBuffer$$.pipe(distinctUntilChanged()),
    ]).pipe(
      tap(([tEvent, translocoHeaderBuffer]) => {
        const translocoReady = tEvent.type === 'translationLoadSuccess';
        this._translocoReady$$.next(translocoReady);

        if (translocoReady && translocoHeaderBuffer) {
          this._layoutService.headerLabel$$.next(
            this._transloco.translate(translocoHeaderBuffer),
          );
        }
      }),
    );
  }
}
