import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from '@core/services/company.service';
import { TranslationsFormCreator } from '@core/utils/translations-form-creator';
import {
  ICreateQuestionCategory,
  IPatchQuestionCategory,
} from '@meeting/store/models/meeting-question-category.model';
import { TalentLocale, i18nObject } from '@models/locales';
import { TranslationsFormGroup } from '@models/translations-form-group.model';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as categoryActions from 'src/app/modules/meeting/store/actions/meeting-category.actions';
import { selectMeetingCategoryLoadingState } from '../../../../meeting/store/selector/meeting-category.selector';
@Component({
  selector: 'blx-admin-meeting-category-modal',
  templateUrl: './admin-meeting-category-modal.component.html',
})
export class AdminMeetingCategoryModalComponent implements OnInit, OnDestroy {
  /**
   * * OBSERVABLES *
   */
  loading$ = this.store.pipe(select(selectMeetingCategoryLoadingState));

  /**
   * * VARIABLES *
   */
  category: ICreateQuestionCategory | IPatchQuestionCategory | null = null;
  locales: TalentLocale[] = [];
  companyLocales: TalentLocale[] = [];

  categoryForm: TranslationsFormGroup = new FormGroup({
    translations: new FormArray<
      FormGroup<{
        locale: FormControl<TalentLocale>;
        text: FormControl<string>;
      }>
    >([]),
    requiredLocale: new FormControl<TalentLocale>('fr'),
    requiredLocaleSource: new FormControl<string>('COMPANY'),
    locales: new FormControl<TalentLocale[]>([]),
  });

  requiredLocale: TalentLocale = null;
  requiredIsDefaultCompanyLocale = false;

  private _unsubscribe$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<AdminMeetingCategoryModalComponent>,
    private store: Store,
    private fb: TranslationsFormCreator,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      category?: IPatchQuestionCategory;
      categoryId?: string;
    },
    private readonly _companyService: CompanyService,
  ) {}

  /**
   * * NG CYCLES *
   */

  ngOnInit(): void {
    this._companyService.currentCompany$
      .pipe(take(1), takeUntil(this._unsubscribe$))
      .subscribe((company) => {
        if (!this.requiredLocale) {
          this.requiredIsDefaultCompanyLocale = true;
          this.requiredLocale = company?.defaultLocale;
        }
        this.companyLocales = company?.locales;
      });

    if (this.data?.category) {
      this.category = this._removeUnusedLocales(this.data.category);
    } else {
      this.category = {
        name: {},
      };
    }

    if (this.category.name)
      this.locales = this.companyLocales.filter(
        (companyLocale) =>
          !Object.keys(this.category.name).includes(companyLocale),
      );

    if (this.locales.includes(this.requiredLocale)) {
      this.locales = this.locales.filter(
        (locale) => locale !== this.requiredLocale,
      );
      this.category.name[this.requiredLocale] = '';
    }

    this.categoryForm = this.fb.createFormArray(
      this.category?.name || {},
      this.requiredLocale,
      'COMPANY',
      this.locales,
    );
  }

  /**
   * * PROTECTED *
   */

  protected isValid(): boolean {
    this.categoryForm.updateValueAndValidity();
    return this.categoryForm.valid;
  }

  protected get translations(): FormArray {
    return this.categoryForm.get('translations') as FormArray;
  }

  protected dispatch(): void {
    const formattedNames = {};
    this.translations.value.forEach(
      (group) => (formattedNames[group.locale] = group.text.trim()),
    );
    if (this.data.categoryId) {
      this.store.dispatch(
        categoryActions.MeetingCategoriesActions.updateMeetingCategory({
          category: {
            id: this.data.categoryId,
            name: formattedNames,
          },
        }),
      );
    } else {
      this.store.dispatch(
        categoryActions.MeetingCategoriesActions.addMeetingCategory({
          category: {
            ...this.category,
            name: formattedNames,
          },
        }),
      );
    }
  }

  /**
   * * PRIVATE *
   */

  private _removeUnusedLocales(
    category: IPatchQuestionCategory,
  ): IPatchQuestionCategory {
    const categoryNames: i18nObject = {};

    this._companyService.availableLocales.map((locale) => {
      if (!category?.name) return;
      if (category.name[locale]) {
        categoryNames[locale] = category.name[locale];
      }
    });

    return { ...category, name: categoryNames };
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
