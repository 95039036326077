import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DownloadToastComponent } from '@core/components/toasts/download-toast.component';
import { ErrorToastComponent } from '@core/components/toasts/error-toast.component';
import { InfoToastComponent } from '@core/components/toasts/info-toast.component';
import { SuccessToastComponent } from '@core/components/toasts/success-toast.component';
import {
  refreshNotifications,
  startNotifPolling,
  stopNotifPolling,
} from '@core/store/actions/notifications.actions';
import { environment } from '@env/environment';
import { UserNotification } from '@models/notifications.model';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { ActiveToast, GlobalConfig, ToastrService } from 'ngx-toastr';
import { merge, Observable, of, timer } from 'rxjs';
import {
  concatMap,
  filter,
  map,
  repeatWhen,
  retry,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

interface InfoOptions {
  message: string;
  translateValue: { value?: string };
  title: null;
  disableTimeout: boolean;
  additionalMessage?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  toastOptions: GlobalConfig;
  private lastInserted: number[] = [];
  private _EventNotificationsMap: Map<number, number>; // eventId => toastId

  constructor(
    private transloco: TranslocoService,
    private http: HttpClient,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private store: Store,
    private actions$: Actions,
    private readonly _authService: AuthenticationService,
  ) {
    this._EventNotificationsMap = new Map();
    this.toastOptions = {
      ...this.toastr.toastrConfig,
      toastClass: 'custom-toast',
      positionClass: 'toast-bottom-center',
      tapToDismiss: false,
    };
  }

  // ---- TOASTS ----
  // SHOW
  private showToast(
    message: string,
    translateValue: { value?: string } = {},
    options: GlobalConfig,
  ): ActiveToast<any> {
    const inserted = this.toastr.show(
      this.transloco.translate(message, translateValue),
      null,
      options,
    );
    if (inserted && inserted.toastId) {
      inserted.toastRef.componentInstance.toastId = inserted.toastId;
      this.lastInserted.push(inserted.toastId);
    }
    return inserted;
  }
  showSuccess(
    message: string,
    translateValue: { value?: string } = {},
  ): ActiveToast<any> {
    const options = cloneDeep(this.toastOptions);
    options.toastComponent = SuccessToastComponent;
    return this.showToast(message, translateValue, options);
  }
  showError(
    message: string,
    translateValue: { value?: string } = {},
  ): ActiveToast<any> {
    const options = cloneDeep(this.toastOptions);
    options.toastComponent = ErrorToastComponent;
    return this.showToast(message, translateValue, {
      ...options,
      timeOut: 10000,
      extendedTimeOut: 0,
    });
  }
  showNotifForEvent(
    eventId: number,
    notifOptions: InfoOptions,
  ): ActiveToast<any> {
    const options = cloneDeep(this.toastOptions);
    options.toastComponent = InfoToastComponent;
    options.disableTimeOut = notifOptions.disableTimeout;
    const additionalMessage = notifOptions.additionalMessage
      ? this.transloco.translate(notifOptions.additionalMessage)
      : null;
    const inserted = this.toastr.show(
      this.transloco.translate(
        notifOptions.message,
        notifOptions.translateValue,
      ),
      notifOptions.title,
      options,
    );
    inserted.toastRef.componentInstance.additionalMessage = additionalMessage;

    if (inserted && inserted.toastId) {
      inserted.toastRef.componentInstance.toastId = inserted.toastId;
      this.lastInserted.push(inserted.toastId);
      this._EventNotificationsMap.set(eventId, inserted.toastId);
    }
    return inserted;
  }

  showDownload(
    eventId: number,
    blob: Blob,
    fileName: string,
  ): ActiveToast<any> {
    const options = cloneDeep(this.toastOptions);
    options.toastComponent = DownloadToastComponent;
    options.disableTimeOut = true;
    const url = this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob),
    );
    const inserted = this.toastr.show('', '', options);
    inserted.toastRef.componentInstance.url = url;
    inserted.toastRef.componentInstance.fileName = fileName;
    if (inserted && inserted.toastId) {
      inserted.toastRef.componentInstance.toastId = inserted.toastId;
      this.lastInserted.push(inserted.toastId);
      this._EventNotificationsMap.set(eventId, inserted.toastId);
    }
    return inserted;
  }

  // HIDE
  hideOne(toastId: number): void {
    this.toastr.remove(toastId);
  }
  hideAll(): void {
    this.toastr.clear();
    this._EventNotificationsMap.clear();
  }
  hideNotifForEvent(eventId: number): void {
    this.toastr.remove(this._EventNotificationsMap.get(eventId));
    this._EventNotificationsMap.delete(eventId);
  }

  // ---- NOTIFICATIONS ----
  get(
    skip = 0,
    limit = 8,
  ): Observable<{
    meta: { total: number };
    notifications: UserNotification[];
  }> {
    /*const URL = `${environment.API_URL}/my/notifications?${qs.stringify({
      meta: {
        skip,
        limit,
        orderBy: { property: 'createdAt', direction: 'desc' },
      },
    })}`;
    return this.http.get<ApiData<UserNotification[], Meta>>(URL).pipe(
      retry(1),
      map(({ meta, data }) => ({ meta, notifications: data })),
    );*/

    return of({
      meta: {
        total: 0,
      },
      notifications: [],
    });
  }

  getUnreadCount(): Observable<number> {
    /*const URL = `${environment.API_URL}/my/notifications?${qs.stringify({
      body: { seen: false },
      meta: { onlyCount: true },
    })}`;

    return this.http.get<ApiData<{}, { total: number }>>(URL).pipe(
      retry(1),
      map(({ meta }) => meta.total),
    );*/

    return of(0);
  }

  markAsRead(notificationId: number): Observable<void> {
    return this.http
      .patch<void>(
        `${environment.API_URL}/my/notifications/${notificationId}`,
        {
          seen: true,
        },
      )
      .pipe(retry(1));
  }

  getNotificationActions(n: UserNotification): { routing: string } {
    switch (n.type) {
      // MY
      case 'MY_PROFILE_EDITED':
        return { routing: `/mybleexo/profile` };
    }
  }

  notificationsPolling() {
    const INTERVAL = 30 * 1000; // 30 seconds
    timer(INTERVAL, INTERVAL)
      .pipe(
        concatMap((tick) =>
          of(tick).pipe(
            withLatestFrom(
              this._authService.currentUser$.pipe(map((user) => !!user)),
            ),
          ),
        ),
        filter(([_, isAuth]) => isAuth),
        map(() => this.store.dispatch(refreshNotifications({ chunkSize: 30 }))),
        takeUntil(
          merge(
            this.actions$.pipe(ofType(stopNotifPolling)),
            of(this._authService.logout()),
          ),
        ),
        repeatWhen((_) => this.actions$.pipe(ofType(startNotifPolling))),
      )
      .subscribe();
  }
}
