import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { IBaseModalConfig } from '@core/services/modals.service';
import { MODAL_CONFIGS } from '@meeting/modals.config';
import { TranslocoModule } from '@ngneat/transloco';

export interface IConfirmationModalConfig extends IBaseModalConfig {
  titleTransloco?: string;
  subTitleTransloco?: string;
  descriptionsTransloco?: string[];
  confirmTranslocoLabel?: string;
  cancelTranslocoLabel?: string;
  warnTransloco?: string;
  googleIcon?: string;
  matIcon?: string;
  color?: 'red' | 'orange';
}

@Component({
  selector: 'app-confirmation-modal',
  styleUrls: ['./confirmation.modal.scss'],
  templateUrl: './confirmation.modal.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslocoModule,
    MatIconModule,
  ],
})
export class ConfirmationModalComponent {
  defaultConfig = MODAL_CONFIGS.DEFAULT_CONFIRMATION_MODAL_CONFIG;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data?: IConfirmationModalConfig,
  ) {}

  close() {
    this.dialogRef.close();
  }

  valid() {
    this.dialogRef.close(true);
  }
}
