import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CapitalizeFirstPipe } from '@core/pipes/capitalize-first.pipe';
import { AuthenticationService } from '@core/services/authentication.service';
import { CompanyService } from '@core/services/company.service';
import { FilesService } from '@core/services/files.service';
import { ModalsService } from '@core/services/modals.service';
import { NotificationService } from '@core/services/notifications.service';
import { PermissionsService } from '@core/services/permissions.service';
import { IPostHogEventConfig } from '@core/services/posthog.service';
import { environment } from '@env/environment';
import {
  EMeetingStepName,
  meetingTimelineAdminSteps,
  MeetingTimelineStep,
} from '@meeting/components/config/meeting-timeline-steps';
import { Step } from '@meeting/components/meeting-survey-timeline-step/meeting-survey-timeline-step.component';
import { MODAL_CONFIGS } from '@meeting/modals.config';
import {
  EMeetingStatuses,
  EParticipantRoles,
  ICampaign,
  ICampaignResult,
  IConductMeetingResult,
  ICreateCampaign,
  ICreateMeeting,
  IFilterFindCampaigns,
  IFilterFindMeetings,
  IMeeting,
  IMeetingResult,
  IParticipant,
  IPatchMeeting,
  IPostMeetingConduct,
  IPrepareMeetingResult,
} from '@meeting/store/models/meeting.model';
import {
  IParticipantAnswerPrepare,
  IParticipantAnswerResult,
} from '@meeting/store/models/participant-answers.model';
import { Company } from '@models/company.model';
import { TranslocoService } from '@ngneat/transloco';
import * as qs from 'qs';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

export interface ISendDownloadedReportEventPayload {
  participant: IParticipant;
  company: Company;
}

@Injectable({
  providedIn: 'root',
})
export class MeetingService {
  private _ENDPOINT_URL = `${environment.API_URL}/meeting`;
  private _meetings$$: BehaviorSubject<IMeeting[]> = new BehaviorSubject<
    IMeeting[]
  >([]);
  _meetings$ = this._meetings$$.asObservable();

  private _campaigns$$: BehaviorSubject<ICampaign[]> = new BehaviorSubject<
    ICampaign[]
  >([]);

  campaigns$ = this._campaigns$$.asObservable();

  // POSTHOG EVENT
  private _emitDownloadedReportEvent$$: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);
  sendDownloadedReportEvent$$: BehaviorSubject<
    ISendDownloadedReportEventPayload | undefined
  > = new BehaviorSubject<ISendDownloadedReportEventPayload | undefined>(
    undefined,
  );

  constructor(
    private _httpClient: HttpClient,
    private _permissionsService: PermissionsService,
    private _notificationService: NotificationService,
    private _translocoService: TranslocoService,
    private _capitalizePipe: CapitalizeFirstPipe,
    private readonly _modalsService: ModalsService,
    private readonly _filesService: FilesService,
    private readonly _authService: AuthenticationService,
    private readonly _companyService: CompanyService,
  ) {
    this._authService.currentUser$.subscribe((user) => {
      if (user) {
        this.refreshMeetings();
        if (this._permissionsService._userPermissions.includes('admin:access'))
          this.refreshCampaigns();
      }
    });

    this._initEmitDownloadedReportEventListener();
  }

  private _initEmitDownloadedReportEventListener() {
    this._emitDownloadedReportEvent$$
      .pipe(
        switchMap((meetingId) => {
          if (!meetingId) return EMPTY;
          return this.getOne(meetingId).pipe(map((meeting) => meeting));
        }),
        switchMap((meeting) => {
          return combineLatest([
            this._authService.currentUser$.pipe(
              map((user) => ({ user, meeting })),
            ),
            this._companyService.currentCompany$,
          ]);
        }),
        tap(([{ user, meeting }, company]) => {
          const participant = meeting.participants.find(
            (p) => p.user.id === user.id,
          );

          this.sendDownloadedReportEvent$$.next({ participant, company });
        }),
      )
      .subscribe();
  }

  refreshMeetings() {
    this.getAll()
      .pipe(take(1))
      .subscribe((meetings) => this._meetings$$.next(meetings));
  }

  refreshCampaigns() {
    this.getAllCampaigns()
      .pipe(
        take(1),
        map((campaigns) => campaigns.data),
      )
      .subscribe((campaigns) => this._campaigns$$.next(campaigns));
  }

  getAll(): Observable<IMeeting[]> {
    return this._httpClient
      .get<{
        data: IMeeting[];
        pagination: { total: number; page: number; totalPages: number };
      }>(this._ENDPOINT_URL + '/meetings/v2')
      .pipe(
        map((data) => data.data),
        map((meetings) => {
          return meetings.map((meeting) => {
            return {
              ...meeting,
              participants: meeting.participants.map((p) => {
                return {
                  ...p,
                  user: p.user ?? {
                    color: p.color,
                    firstname: p.firstName,
                    lastname: p.lastName,
                    initials: p.initials,
                  },
                };
              }),
            };
          });
        }),
      );
  }

  getAllCampaigns(): Observable<{
    data: ICampaignResult[];
    pagination: { total: number; page: number; totalPages: number };
  }> {
    return this._httpClient
      .get<{
        data: ICampaignResult[];
        pagination: { total: number; page: number; totalPages: number };
      }>(this._ENDPOINT_URL + '/meetings/campaign')
      .pipe();
  }

  getMeetingsWithFilters$(filters?: IFilterFindMeetings) {
    let endpoint = `${environment.API_URL}/meeting/meetings/v2`;
    if (filters) {
      endpoint += '?' + qs.stringify(filters);
    }
    return this._httpClient.get<{
      data: IMeetingResult[];
      pagination: { total: number; page: number; totalPages: number };
    }>(endpoint);
  }

  isLastMeetingOfCampaign$(meetingId: string): Observable<boolean | undefined> {
    return this.getOne(meetingId).pipe(
      switchMap((meeting) => {
        if (!meeting) return of(undefined);

        if (!meeting.campaignId)
          return of({
            data: [],
          });

        const filters: IFilterFindMeetings = {
          campaignIds: [meeting.campaignId],
        };

        return this.getMeetingsWithFilters$(filters);
      }),
      map((res) => res.data?.length ?? 0),
      map((count) => !!(count <= 1)),
    );
  }

  getCampaignsWithFilters$(filters?: IFilterFindCampaigns) {
    let endpoint = `${environment.API_URL}/meeting/meetings/campaign`;
    if (filters) {
      endpoint += '?' + qs.stringify(filters);
    }
    return this._httpClient.get<{
      data: ICampaignResult[];
      pagination: { total: number; page: number; totalPages: number };
    }>(endpoint);
  }

  getOne(meetingId: string): Observable<IMeetingResult> {
    return this._httpClient.get<IMeetingResult>(
      this._ENDPOINT_URL + '/meetings/' + meetingId,
    );
  }

  getOneCampaign(campaignId: string): Observable<ICampaignResult> {
    return this._httpClient.get<ICampaignResult>(
      this._ENDPOINT_URL + '/meetings/campaign/' + campaignId,
    );
  }

  getCampaignParticipantsById(
    campaignId: string,
  ): Observable<ICampaignResult & { meetings: IMeetingResult[] }> {
    return this._httpClient.get<
      ICampaignResult & { meetings: IMeetingResult[] }
    >(
      this._ENDPOINT_URL + '/meetings/campaign/' + campaignId + '/participants',
    );
  }

  getDraft(meetingId: string): Observable<IPrepareMeetingResult> {
    return this._httpClient.get<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetings/' + meetingId,
    );
  }

  getPrepare(meetingId: string): Observable<IPrepareMeetingResult> {
    return this._httpClient.get<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId,
    );
  }

  startOne(meetingId: string): Observable<IMeetingResult> {
    return this._httpClient.post<IMeetingResult>(
      this._ENDPOINT_URL + '/meetingsScheduler/start',
      { id: meetingId },
    );
  }

  startOneCampaign(
    campaignId: string,
    campaignTitle?: string,
  ): Observable<ICampaignResult> {
    return this._httpClient
      .post<ICampaignResult>(
        `${this._ENDPOINT_URL}/meetings/campaign/${campaignId}/start`,
        {},
      )
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.CAMPAIGN_HAS_STARTED_TOAST',
            { value: campaignTitle ?? campaignId },
          );
        }),
      );
  }

  getMultiple(meetingIds: string[]): Observable<IMeetingResult[]> {
    const meetingRequests$ = combineLatest(
      meetingIds.map((meetingId) => this.getOne(meetingId)),
    );
    return meetingRequests$;
  }

  getPrepareMeetingAnswer(
    meetingId: string,
  ): Observable<IPrepareMeetingResult> {
    return this._httpClient.get<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId,
    );
  }

  getConductMeetingAnswer(
    meetingId: string,
  ): Observable<IConductMeetingResult> {
    return this._httpClient.get<IConductMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/conduct/' + meetingId,
    );
  }

  postMeetingAnswer(
    meetingId: string,
    questions: IParticipantAnswerPrepare[],
  ): Observable<IPrepareMeetingResult> {
    return this._httpClient.post<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId,
      { questions },
    );
  }

  postMeetingAnswerDraft(
    meetingId: string,
    questions: IParticipantAnswerPrepare[],
  ): Observable<IParticipantAnswerResult[]> {
    return this._httpClient.post<IParticipantAnswerResult[]>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId + '/draft',
      { questions },
    );
  }

  postConductMeetingAnswer(
    meetingId: string,
    payload: IPostMeetingConduct,
  ): Observable<IMeetingResult> {
    return this._httpClient.post<IMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/conduct/' + meetingId,
      payload,
    );
  }

  postSignMeeting(
    meetingId: string,
    payload: { signed: boolean; comment?: string },
  ): Observable<IMeetingResult> {
    return this._httpClient.post<IMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/sign/' + meetingId,
      payload,
    );
  }

  create(meeting: ICreateMeeting): Observable<IMeetingResult> {
    return this._httpClient.post<IMeetingResult>(
      this._ENDPOINT_URL + '/meetings',
      meeting,
    );
  }

  createCampaign(campaign: ICreateCampaign): Observable<ICampaignResult> {
    return this._httpClient
      .post<ICampaignResult>(
        this._ENDPOINT_URL + '/meetings/campaign',
        campaign,
      )
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.TOAST_CAMPAIGN_CREATED',
            { value: campaign.title },
          );
        }),
      );
  }

  patch(
    meetingId: string,
    meetingToPatch: IPatchMeeting,
  ): Observable<IMeetingResult> {
    return this._httpClient.patch<IMeetingResult>(
      `${this._ENDPOINT_URL}/meetings/${meetingId}`,
      meetingToPatch,
    );
  }

  patchCampaignEndDate(campaignId: string, newEndDate: string) {
    return this._httpClient
      .patch<ICampaignResult>(
        `${this._ENDPOINT_URL}/meetings/campaign/${campaignId}/end-date`,
        { endDate: newEndDate },
      )
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.TOAST_DATES_HAS_BEEN_UPDATED',
          );
        }),
      );
  }

  /**
   * Change the reviewer for a meeting
   * @param newReviewerUserId the user id of the next reviewer
   * @param meetingId
   * @param fromView will affect the toast message
   * @returns
   */
  patchReviewerUserIdByMeetingId(
    newReviewerUserId: number,
    meetingId: string,
    fromView: 'meeting' | 'campaign' = 'campaign',
  ) {
    return this._httpClient
      .patch<IMeetingResult>(
        `${this._ENDPOINT_URL}/meetings/${meetingId}/reviewer/${newReviewerUserId}`,
        {},
      )
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.' +
              (fromView === 'campaign'
                ? 'REVIEWER_HAS_BEEN_UPDATED_CAMPAIGN_TOAST'
                : 'REVIEWER_HAS_BEEN_UPDATED_MEETING_TOAST'),
          );
        }),
      );
  }

  sendCampaignReminder(campaignId: string) {
    return this._httpClient
      .post<ICampaignResult>(
        `${this._ENDPOINT_URL}/meetings/campaign/${campaignId}/remind`,
        {},
      )
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.TOAST_REMINDERS_HAS_BEEN_SEND',
          );
        }),
      );
  }

  addCampaignParticipants(
    campaignId: string,
    participants: Array<{ reviewee: { id: number }; reviewer: { id: number } }>,
  ) {
    return this._httpClient
      .put<{
        meetings: string[];
      }>(
        `${this._ENDPOINT_URL}/meetings/campaign/${campaignId}/participants`,
        participants,
      )
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            participants.length === 1
              ? 'MEETING.TOAST_PARTICIPANT_HAS_BEEN_ADDED'
              : 'MEETING.TOAST_PARTICIPANTS_HAVE_BEEN_ADDED',
          );
        }),
      );
  }

  delete(id: string): Observable<IMeetingResult> {
    return this._httpClient.delete<IMeetingResult>(
      `${this._ENDPOINT_URL}/meetings/${id}`,
    );
  }

  computeTimeLineSteps(meeting: IMeeting, viewRole: EParticipantRoles): Step[] {
    let activeStateFound = false;
    const steps = [...meetingTimelineAdminSteps];
    for (let i = 0; i < meetingTimelineAdminSteps.length; i++) {
      if (activeStateFound) {
        steps[i].state = 'next';
      } else {
        if (
          meetingTimelineAdminSteps[i].possibleStatuses.includes(meeting.status)
        ) {
          activeStateFound = true;
          steps[i].state = 'active';
        } else {
          steps[i].state = 'done';
        }
      }
    }
    const prepareStep = steps.find(
      (step) => step.name === EMeetingStepName.PREPARE,
    );
    const signStep = steps.find((step) => step.name === EMeetingStepName.SIGN);

    this.computePrepareStepExtras(
      prepareStep,
      meeting.status,
      meeting,
      viewRole,
    );
    this.computeSignStepExtras(signStep, meeting.status, meeting, viewRole);
    return steps;
  }

  getCurrentMeetingStep(meeting: IMeeting, viewRole: EParticipantRoles): Step {
    return this.computeTimeLineSteps(meeting, viewRole).find(
      (step) => step.state === 'active',
    );
  }

  computePrepareStepExtras(
    prepareStep: MeetingTimelineStep,
    status: EMeetingStatuses,
    meeting: IMeeting,
    viewRole: EParticipantRoles,
  ): void {
    prepareStep.tooltip = '';
    prepareStep.textExtra = '';
    if (status === EMeetingStatuses.STARTED) {
      prepareStep.textExtra = '(0/2)';
      prepareStep.tooltip = this._translocoService.translate(
        'MEETING.TIMELINE.TOOLTIP_WAITING_BOTH',
      );
    } else if (
      status === EMeetingStatuses.PREPARED_BY_REVIEWEE ||
      status === EMeetingStatuses.PREPARED_BY_REVIEWER
    ) {
      if (
        status === EMeetingStatuses.PREPARED_BY_REVIEWER &&
        viewRole === EParticipantRoles.REVIEWEE
      ) {
        prepareStep.tooltip = this._translocoService.translate(
          'MEETING.TIMELINE.TOOLTIP_WAITING_FOR_YOU',
        );
      }

      if (
        status === EMeetingStatuses.PREPARED_BY_REVIEWEE &&
        viewRole === EParticipantRoles.REVIEWEE
      ) {
        const reviewer = meeting.participants.find(
          (user) => user.role === EParticipantRoles.REVIEWER,
        );
        prepareStep.tooltip = this._translocoService.translate(
          'MEETING.TIMELINE.TOOLTIP_WAITING_REVIEWER',
          {
            username: `${this._capitalizePipe.transform(reviewer.firstName)} ${reviewer.lastName}`,
          },
        );
      }

      if (
        status === EMeetingStatuses.PREPARED_BY_REVIEWEE &&
        viewRole === EParticipantRoles.REVIEWER
      ) {
        prepareStep.tooltip = this._translocoService.translate(
          'MEETING.TIMELINE.TOOLTIP_WAITING_FOR_YOU',
        );
      }

      if (
        status === EMeetingStatuses.PREPARED_BY_REVIEWER &&
        viewRole === EParticipantRoles.REVIEWER
      ) {
        const reviewee = meeting.participants.find(
          (user) => user.role === EParticipantRoles.REVIEWEE,
        );
        prepareStep.tooltip = this._translocoService.translate(
          'MEETING.TIMELINE.TOOLTIP_WAITING_REVIEWEE',
          {
            username: `${this._capitalizePipe.transform(reviewee.firstName)} ${reviewee.lastName}`,
          },
        );
      }
      prepareStep.textExtra = '(1/2)';
    }
  }

  computeSignStepExtras(
    signStep: MeetingTimelineStep,
    status: EMeetingStatuses,
    meeting: IMeeting,
    viewRole: EParticipantRoles,
  ): void {
    signStep.tooltip = '';
    signStep.textExtra = '';
    if (
      status === EMeetingStatuses.READY_TO_SIGN ||
      status === EMeetingStatuses.FINISHED_BY_REVIEWEE ||
      status === EMeetingStatuses.FINISHED_BY_REVIEWER
    ) {
      if (status === EMeetingStatuses.READY_TO_SIGN) {
        signStep.textExtra = '(0/2)';
        signStep.tooltip = this._translocoService.translate(
          'MEETING.TIMELINE.TOOLTIP_WAITING_BOTH',
        );
      } else if (
        status === EMeetingStatuses.FINISHED_BY_REVIEWER ||
        status === EMeetingStatuses.FINISHED_BY_REVIEWEE
      ) {
        if (
          status === EMeetingStatuses.FINISHED_BY_REVIEWER &&
          viewRole === EParticipantRoles.REVIEWEE
        ) {
          signStep.tooltip = this._translocoService.translate(
            'MEETING.TIMELINE.TOOLTIP_WAITING_FOR_YOU',
          );
        }

        if (
          status === EMeetingStatuses.FINISHED_BY_REVIEWEE &&
          viewRole === EParticipantRoles.REVIEWEE
        ) {
          const reviewer = meeting.participants.find(
            (user) => user.role === EParticipantRoles.REVIEWER,
          );
          signStep.tooltip = this._translocoService.translate(
            'MEETING.TIMELINE.TOOLTIP_WAITING_REVIEWER',
            {
              username: `${this._capitalizePipe.transform(reviewer.firstName)} ${reviewer.lastName}`,
            },
          );
        }

        if (
          status === EMeetingStatuses.FINISHED_BY_REVIEWEE &&
          viewRole === EParticipantRoles.REVIEWER
        ) {
          signStep.tooltip = this._translocoService.translate(
            'MEETING.TIMELINE.TOOLTIP_WAITING_FOR_YOU',
          );
        }

        if (
          status === EMeetingStatuses.FINISHED_BY_REVIEWER &&
          viewRole === EParticipantRoles.REVIEWER
        ) {
          const reviewee = meeting.participants.find(
            (user) => user.role === EParticipantRoles.REVIEWEE,
          );
          signStep.tooltip = this._translocoService.translate(
            'MEETING.TIMELINE.TOOLTIP_WAITING_REVIEWEE',
            {
              username: `${this._capitalizePipe.transform(reviewee.firstName)} ${reviewee.lastName}`,
            },
          );
        }
        signStep.textExtra = '(1/2)';
      }
    }
  }

  downloadMeetingExport(meeting: IMeetingResult): void {
    const endpoint = `meeting/meetings/${meeting.id}/download`;
    const posthogConfig: IPostHogEventConfig = {
      event: this._emitDownloadedReportEvent$$,
      payload: meeting.id,
    };
    this._filesService.downloadFile(endpoint, 'pdf', undefined, posthogConfig);
  }

  openDeleteMeetingModal$(
    meetingId: string,
  ): Observable<{ deleted: boolean; isLast: boolean }> {
    return this.isLastMeetingOfCampaign$(meetingId).pipe(
      take(1),
      switchMap((isLastMeetingOfCampaign) => {
        return this._modalsService
          .openConfirmationModal$(
            isLastMeetingOfCampaign
              ? MODAL_CONFIGS.DELETE_MEETING_THUS_CAMPAIGN_MODAL_CONFIG
              : MODAL_CONFIGS.DELETE_MEETING_MODAL_CONFIG,
          )
          .pipe(
            switchMap((confirm) => {
              if (!confirm)
                return of({ deleted: false, isLast: isLastMeetingOfCampaign });
              return this.delete(meetingId).pipe(
                map((res) => ({
                  deleted: !!res,
                  isLast: isLastMeetingOfCampaign,
                })),
              );
            }),
          );
      }),
    );
  }
}
