import { createSelector } from '@ngrx/store';
import {
  meetingTemplateAdapter,
  meetingTemplatesFeatureKey,
} from '../reducers/meeting-template.reducer';
import { selectMeetingState } from './meeting.selectors';

export const selectMeetingTemplateState = createSelector(
  selectMeetingState,
  (meeting) => meeting[meetingTemplatesFeatureKey],
);

export const {
  selectIds: selectMeetingTemplateIds,
  selectEntities: selectMeetingTemplateEntities,
  selectAll: selectAllMeetingTemplates,
  selectTotal: selectMeetingTemplatesTotal,
} = meetingTemplateAdapter.getSelectors(selectMeetingTemplateState);

export const selectMeetingTemplateLoadingState = createSelector(
  selectMeetingTemplateState,
  (state) => state.loading,
);

export const selectMeetingTemplateIsLoadedState = createSelector(
  selectMeetingTemplateState,
  (state) => state.isLoaded,
);

export const getSelectedMeetingTemplateId = createSelector(
  selectMeetingTemplateState,
  (state) => state.selectedTemplateId,
);

export const selectMeetingTemplatesByType = (id: string) =>
  createSelector(selectAllMeetingTemplates, (templates) =>
    templates.filter((t) => t.typeId === id),
  );

export const selectMeetingTemplateById = (id: string) =>
  createSelector(selectAllMeetingTemplates, (templates) => {
    return (templates ?? []).find((t) => t.id === id);
  });

export const selectMeetingTemplatesByIds = (id: string[]) =>
  createSelector(selectAllMeetingTemplates, (templates) => {
    return (templates ?? []).filter((t) => id.includes(t.id));
  });
