import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';

export function enableSentry() {
  Sentry.init({
    dsn: 'https://470c3b45e0c1fe1cc7a49c6769c54231@o21383.ingest.us.sentry.io/4507344281206784',
    environment: environment.name,
    integrations: [Sentry.browserTracingIntegration()],
    // performance monitoring
    tracesSampleRate: 1.0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api*\.talent\.silae\.fr/,
    ],
  });
}
