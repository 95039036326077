import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { toastAnim, ToastPreset, toastPresets } from './toast-vars';

@Component({
  selector: '[download-toast]',
  styleUrls: ['./toast-styles.scss'],
  template: `
    <div
      class="custom-toast shadow-xl flex items-center justify-center mb-6 download-file"
    >
      <svg-icon icon="toast-info" class="solid text-base text-white"></svg-icon>

      <div
        class="grow download-text flex flex-row items-center"
        style="overflow: hidden"
      >
        <span *ngIf="message">{{ message }}</span>
        <span class="truncate">
          {{ 'STATUS.DOWNLOAD_READY' | transloco }}
        </span>
        <a
          target="_blank"
          class="ml-auto"
          [href]="url"
          [download]="fileName"
          (click)="close($event)"
        >
          <span
            class="flex flex-row items-center justify-center gap-2 download-btn"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1601_22685)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.3927 8.95284C13.6096 8.73589 13.6096 8.38413 13.3927 8.16717C13.1757 7.95021 12.824 7.95021 12.607 8.16717L8.55539 12.2188V1.33778C8.55539 1.03096 8.30666 0.782227 7.99984 0.782227C7.69301 0.782227 7.44428 1.03096 7.44428 1.33778L7.44428 12.2188L3.39267 8.16717C3.17572 7.95021 2.82396 7.95021 2.607 8.16717C2.39004 8.38413 2.39004 8.73588 2.607 8.95284L7.607 13.9528C7.7087 14.0545 7.84002 14.1086 7.97319 14.1149C7.98207 14.1153 7.99095 14.1156 7.99984 14.1156C8.07517 14.1156 8.14699 14.1006 8.2125 14.0734C8.27802 14.0463 8.33941 14.0061 8.39268 13.9528L13.3927 8.95284Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.0517578 14.9879C0.0517578 14.7118 0.275615 14.4879 0.551758 14.4879H15.4482C15.7244 14.4879 15.9482 14.7118 15.9482 14.9879C15.9482 15.264 15.7244 15.4879 15.4482 15.4879H0.551758C0.275615 15.4879 0.0517578 15.264 0.0517578 14.9879Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1601_22685">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <span>{{ 'GLOBAL.DOWNLOAD' | transloco | capitalize }}</span>
          </span>
        </a>
      </div>
      <div (click)="close($event)" class="cursor-pointer">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.0303 5.03033C20.3232 4.73744 20.3232 4.26256 20.0303 3.96967C19.7374 3.67678 19.2626 3.67678 18.9697 3.96967L12 10.9393L5.03033 3.96967C4.73744 3.67678 4.26256 3.67678 3.96967 3.96967C3.67678 4.26256 3.67678 4.73744 3.96967 5.03033L10.9393 12L3.96967 18.9697C3.67678 19.2626 3.67678 19.7374 3.96967 20.0303C4.26256 20.3232 4.73744 20.3232 5.03033 20.0303L12 13.0607L18.9697 20.0303C19.2626 20.3232 19.7374 20.3232 20.0303 20.0303C20.3232 19.7374 20.3232 19.2626 20.0303 18.9697L13.0607 12L20.0303 5.03033Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  `,
  animations: [toastAnim],
})
export class DownloadToastComponent extends Toast {
  @Input() url: string;
  @Input() fileName: string;
  @Input() toastId: number;
  presets: ToastPreset;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
    this.presets = toastPresets.success;
  }

  close(event): void {
    event.stopPropagation();
    this.toastrService.remove(this.toastId);
  }
}
