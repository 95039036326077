export const MIMETYPES = {
  csv: 'text/csv',
  pdf: 'application/pdf',
  ppt: 'application/vnd.ms-powerpoint',
  xls: 'application/vnd.ms-excel',
  zip: 'application/zip',
};

export const PDF_OPTIONS = {
  A4: {
    format: 'A4',
    printBackground: true,
  },
};
