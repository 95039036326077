import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TalentLocale, i18nObject } from '@models/locales';
import { NoWhitespaceValidator } from '@shared/validators/validators';

@Injectable({
  providedIn: 'root',
})
export class TranslationsFormCreator {
  constructor(private _formBuilder: FormBuilder) {}

  createFormArray(
    names: i18nObject,
    requiredLocale: TalentLocale,
    requiredLocaleSource: string,
    localesToBeTranslated: TalentLocale[],
    maxLength?: number,
  ): FormGroup<{
    translations: FormArray<
      FormGroup<{
        locale: FormControl<TalentLocale>;
        text: FormControl<string>;
      }>
    >;
    requiredLocale: FormControl<TalentLocale>;
    requiredLocaleSource: FormControl<string>;
    locales: FormControl<TalentLocale[]>;
  }> {
    const formGroup = new FormGroup<{
      translations: FormArray<
        FormGroup<{
          locale: FormControl<TalentLocale>;
          text: FormControl<string>;
        }>
      >;
      requiredLocale: FormControl<TalentLocale>;
      requiredLocaleSource: FormControl<string>;
      locales: FormControl<TalentLocale[]>;
    }>({
      translations: new FormArray([]),
      requiredLocale: new FormControl<TalentLocale>('fr'),
      requiredLocaleSource: new FormControl<string>('COMPANY'),
      locales: new FormControl<TalentLocale[]>([]),
    });

    const translations = formGroup.get('translations') as FormArray<
      FormGroup<{
        locale: FormControl<TalentLocale>;
        text: FormControl<string>;
      }>
    >;

    formGroup.setControl(
      'requiredLocale',
      new FormControl<TalentLocale>(requiredLocale),
    );
    formGroup.setControl(
      'requiredLocaleSource',
      new FormControl<string>(requiredLocaleSource),
    );
    formGroup.setControl(
      'locales',
      new FormControl<TalentLocale[]>(localesToBeTranslated),
    );

    Object.keys(names).forEach((locale: TalentLocale) => {
      translations.push(
        this._createLocaleFormGroup(locale, names[locale], maxLength),
      );
    });
    return formGroup;
  }

  private _createLocaleFormGroup(
    locale: TalentLocale,
    text = '',
    maxLength?: number,
  ): FormGroup<{
    locale: FormControl<TalentLocale>;
    text: FormControl<string>;
  }> {
    return this._formBuilder.group({
      locale,
      text: [
        text,
        Validators.compose([
          Validators.maxLength(maxLength ? maxLength : 30),
          NoWhitespaceValidator(),
        ]),
      ],
    });
  }
}
