import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { CoreModule } from '@core/core.module';
import { GlobalErrorHandler } from '@core/errorHandler/dev-error-handler';
import { PageUrlHeaderInterceptor } from '@core/interceptors/page-url.interceptor';
import { NotificationsEffects } from '@core/store/effects/notifications.effects';
import * as fromNotifs from '@core/store/reducers/notifications.reducer';
import { environment } from '@env/environment';
import { MeetingCategoryEffects } from '@meeting/store/effects/meeting-category.effects';
import { MeetingTemplateEffects } from '@meeting/store/effects/meeting-template.effects';
import { MeetingTypesEffects } from '@meeting/store/effects/meeting-type.effects';
import { MeetingsEffects } from '@meeting/store/effects/meeting.effects';
import * as fromMeeting from '@meeting/store/reducers/meeting.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { DateAdapterCustom } from '@shared/adapters/date-adapter';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { metaReducers, reducers } from './appStore/app.reducers';
import { ToastsEffects } from './core/store/effects/toast.effects';
import { TranslocoRootModule } from './transloco/transloco-root.module';

const NGRX_ROOT = [
  EffectsModule.forRoot([]),
  StoreModule.forRoot(reducers, {
    metaReducers,
    runtimeChecks: {},
  }),
  StoreRouterConnectingModule.forRoot({
    routerState: RouterState.Minimal,
  }),
];

const NGRX_TOOLING = [
  !environment.production ? StoreDevtoolsModule.instrument() : [],
  StoreDevtoolsModule.instrument({
    maxAge: 25,
    logOnly: environment.production,
    name: 'Bleexo Store',
  }),
];

const NGRX_FEATURE_STATES = [
  EffectsModule.forFeature([ToastsEffects, NotificationsEffects]),
  StoreModule.forFeature(
    fromNotifs.notificationsFeatureKey,
    fromNotifs.reducer,
  ),
  StoreModule.forFeature(
    fromMeeting.meetingFeatureKey,
    fromMeeting.meetingReducers,
  ),
  EffectsModule.forFeature([MeetingTemplateEffects]),
  EffectsModule.forFeature([MeetingTypesEffects]),
  EffectsModule.forFeature([MeetingCategoryEffects]),
  EffectsModule.forFeature([MeetingsEffects]),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMenuModule,
    MatDialogModule,
    CoreModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: (_) => localStorage.getItem('token'),
        allowedDomains: [environment.WHITELISTED_DOMAIN],
      },
    }),
    ToastrModule.forRoot(),
    MatNativeDateModule,
    NGRX_ROOT,
    NGRX_TOOLING,
    NGRX_FEATURE_STATES,
    TranslocoRootModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: DateAdapterCustom },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PageUrlHeaderInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: environment.production
        ? Sentry.createErrorHandler({ showDialog: false })
        : undefined,
    },
    {
      provide: ErrorHandler,
      useClass: environment.production ? undefined : GlobalErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
