import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { IBaseModalConfig } from '@core/services/modals.service';
import { TranslocoModule } from '@ngneat/transloco';

export interface IPendingChangesModalConfig extends IBaseModalConfig {
  titleTransloco?: string;
  descriptionsTransloco?: string[];
  confirmTranslocoLabel?: string;
  cancelTranslocoLabel?: string;
}

@Component({
  selector: 'app-pending-changes-modal',
  styleUrls: ['./pending-changes.modal.scss'],
  templateUrl: './pending-changes.modal.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslocoModule,
    MatIconModule,
  ],
})
export class PendingChangesModalComponent {
  defaultConfig: IPendingChangesModalConfig = {
    titleTransloco: 'MODALS.PENDING_CHANGES.TITLE',
    descriptionsTransloco: [
      'MODALS.PENDING_CHANGES.DESCRIPTION',
      'MODALS.PENDING_CHANGES.DESCRIPTION_2',
    ],
    confirmTranslocoLabel: 'MODALS.PENDING_CHANGES.CANCEL',
    cancelTranslocoLabel: 'MODALS.PENDING_CHANGES.CONFIRM',
  };

  constructor(
    public dialogRef: MatDialogRef<PendingChangesModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data?: IPendingChangesModalConfig,
  ) {}

  close() {
    this.dialogRef.close();
  }

  valid() {
    this.dialogRef.close(true);
  }
}
