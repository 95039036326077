<div class="z-10 flex flex-row items-center justify-end px-3 pt-3"
     (click)="close()">
  <mat-icon class="cursor-pointer">close</mat-icon>
</div>
<div class="flex flex-row items-center justify-start gap-1 px-6 pb-4 -mt-3 b-4">
  <div class="flex items-center justify-center p-2 icon"
       [ngClass]="data?.color ?? defaultConfig.color">
    <ng-container *ngIf="data?.matIcon">
      <mat-icon>{{ data.matIcon }}</mat-icon>
    </ng-container>
    <ng-container *ngIf="data?.googleIcon">
      <span class="material-symbols-outlined">
       {{ data.googleIcon }}
        </span>
    </ng-container>
  </div>
  <div class="px-2">
    <h3>
      {{ (data?.titleTransloco ?? defaultConfig.titleTransloco) |
      transloco }}
    </h3>
  </div>
</div>

<ng-container *ngIf="data.subTitleTransloco">
  <div class="flex flex-col gap-2 px-6 pb-5 text-sm">
    <span
       [innerHTML]="data.subTitleTransloco |
        transloco"></span>
  </div>
</ng-container>

<span *ngIf="data?.warnTransloco" class="block pl-6 mt-3 font-bold warning" [ngClass]="{'mb-6': !data?.descriptionsTransloco }">{{ data.warnTransloco | transloco }}</span>

<ng-container *ngIf="(data?.descriptionsTransloco?.length ?? 0) > 0">
  <ul class="flex flex-col gap-2 px-6 pb-5 mt-3 ml-6 text-sm">
    <li *ngFor="let descriptionTransloco of (data?.descriptionsTransloco ?? defaultConfig.descriptionsTransloco)"
        [innerHTML]="descriptionTransloco |
          transloco"></li>
  </ul>
</ng-container>

<div class="flex flex-row items-center justify-between w-full gap-8 px-4 pt-4 pb-4">
  <div class="cursor-pointer cancel-button rounded-3xl"
       (click)="close()">
    <span class="px-4">
            {{ (data?.cancelTranslocoLabel ?? defaultConfig.cancelTranslocoLabel) |
              transloco }}</span>
  </div>

  <button mat-raised-button
          color="primary"
          class="rounded-3xl"
          (click)="valid()">
    <span class="px-4">
      {{ (data?.confirmTranslocoLabel ?? defaultConfig.confirmTranslocoLabel) |
        transloco }}</span>
  </button>

</div>