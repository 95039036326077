import { IConfirmationModalConfig } from '@shared/modals/confirmation/confirmation.modal';

const DELETE_CAMPAIGN_MODAL_CONFIG: IConfirmationModalConfig = {
  titleTransloco: 'MEETING.MODALS.CAMPAIGN_DELETION.TITLE',
  subTitleTransloco: 'MEETING.MODALS.CAMPAIGN_DELETION.SUBTITLE',
  warnTransloco: 'MEETING.MODALS.CAMPAIGN_DELETION.WARNING',
  descriptionsTransloco: [
    'MEETING.MODALS.CAMPAIGN_DELETION.DESCRIPTION_1',
    'MEETING.MODALS.CAMPAIGN_DELETION.DESCRIPTION_2',
  ],
  cancelTranslocoLabel: 'MEETING.MODALS.CAMPAIGN_DELETION.CANCEL',
  confirmTranslocoLabel: 'MEETING.MODALS.CAMPAIGN_DELETION.CONFIRM',
  googleIcon: 'delete',
};

const PARTIAL_EXPORT_CAMPAIGN_MODAL_CONFIG: IConfirmationModalConfig = {
  titleTransloco: 'CAMPAIGN.MODALS.PARTIAL_EXPORT.TITLE',
  subTitleTransloco: 'CAMPAIGN.MODALS.PARTIAL_EXPORT.SUBTITLE',
  warnTransloco: 'CAMPAIGN.MODALS.PARTIAL_EXPORT.WARNING',
  cancelTranslocoLabel: 'CAMPAIGN.MODALS.PARTIAL_EXPORT.CANCEL',
  confirmTranslocoLabel: 'CAMPAIGN.MODALS.PARTIAL_EXPORT.CONFIRM',
  googleIcon: 'error',
  color: 'orange',
};

const DELETE_MEETING_MODAL_CONFIG: IConfirmationModalConfig = {
  titleTransloco: 'MEETING.MODALS.MEETING_DELETION.TITLE',
  subTitleTransloco: 'MEETING.MODALS.MEETING_DELETION.SUBTITLE',
  warnTransloco: 'MEETING.MODALS.MEETING_DELETION.WARNING',
  descriptionsTransloco: [
    'MEETING.MODALS.MEETING_DELETION.DESCRIPTION_2',
    'MEETING.MODALS.MEETING_DELETION.DESCRIPTION_3',
  ],
  cancelTranslocoLabel: 'MEETING.MODALS.MEETING_DELETION.CANCEL',
  confirmTranslocoLabel: 'MEETING.MODALS.MEETING_DELETION.CONFIRM',
  googleIcon: 'delete',
};

const DELETE_MEETING_THUS_CAMPAIGN_MODAL_CONFIG: IConfirmationModalConfig = {
  titleTransloco: 'MEETING.MODALS.MEETING_DELETION.TITLE',
  subTitleTransloco: 'MEETING.MODALS.MEETING_DELETION.SUBTITLE_CAMPAIGN',
  warnTransloco: 'MEETING.MODALS.MEETING_DELETION.WARNING',
  descriptionsTransloco: [
    'MEETING.MODALS.MEETING_DELETION.DESCRIPTION_1',
    'MEETING.MODALS.MEETING_DELETION.DESCRIPTION_2',
    'MEETING.MODALS.MEETING_DELETION.DESCRIPTION_3',
  ],
  cancelTranslocoLabel: 'MEETING.MODALS.MEETING_DELETION.CANCEL',
  confirmTranslocoLabel: 'MEETING.MODALS.MEETING_DELETION.CONFIRM',
  googleIcon: 'delete',
};

const DEFAULT_CONFIRMATION_MODAL_CONFIG: IConfirmationModalConfig = {
  titleTransloco: 'MODALS.PENDING_CHANGES.TITLE',
  descriptionsTransloco: [
    'MODALS.PENDING_CHANGES.DESCRIPTION_1',
    'MODALS.PENDING_CHANGES.DESCRIPTION_2',
  ],
  cancelTranslocoLabel: 'MODALS.PENDING_CHANGES.CONFIRM',
  confirmTranslocoLabel: 'MODALS.PENDING_CHANGES.CANCEL',
  color: 'red',
};

export const MODAL_CONFIGS = {
  DELETE_CAMPAIGN_MODAL_CONFIG: DELETE_CAMPAIGN_MODAL_CONFIG,
  PARTIAL_EXPORT_CAMPAIGN_MODAL_CONFIG: PARTIAL_EXPORT_CAMPAIGN_MODAL_CONFIG,
  DELETE_MEETING_MODAL_CONFIG: DELETE_MEETING_MODAL_CONFIG,
  DELETE_MEETING_THUS_CAMPAIGN_MODAL_CONFIG:
    DELETE_MEETING_THUS_CAMPAIGN_MODAL_CONFIG,
  DEFAULT_CONFIRMATION_MODAL_CONFIG: DEFAULT_CONFIRMATION_MODAL_CONFIG,
};
