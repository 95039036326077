import { Route } from '@angular/router';
import { TalentLocale } from './locales';
import { ModulePermission, Permission } from './permissions.model';

export interface TalentRoute extends Route {
  data?: {
    expectedPermissions?: Permission[];
    permissionsOperator?: 'AND' | 'OR';
    locale?: TalentLocale;
    hasModule?: ModulePermission;
    featureFlag?: string;
    featureVersions?: string[];
  };
  children?: TalentRoute[];
}

export enum TALENT_TRANSLOCO_HEADER_FROM_ROUTE {}
