<div class="z-10 flex flex-row items-center justify-end px-3 pt-3"
     (click)="close()">
  <mat-icon class="cursor-pointer">close</mat-icon>
</div>
<div class="flex flex-row items-center justify-start gap-2 px-6 pb-4 -mt-3 b-4">
  <div class="flex items-center justify-center p-2 warning">
    <mat-icon>error_outline</mat-icon>
  </div>
  <div class="px-2">
    <h3>
      {{ (data?.titleTransloco ?? defaultConfig.titleTransloco) |
      transloco }}
    </h3>
  </div>
</div>
<ng-container>
  <div class="flex flex-col gap-2 px-8 pb-5 text-sm">
    <span *ngFor="let descriptionTransloco of (data?.descriptionsTransloco ?? defaultConfig.descriptionsTransloco)"
       [innerHTML]="descriptionTransloco |
        transloco"></span>
  </div>
</ng-container>

<div class="flex flex-row items-center justify-between w-full gap-8 px-4 pt-4 pb-4">
  <button mat-button
          class="cancel-button"
          (click)="valid()">
    {{ (data?.cancelTranslocoLabel ?? defaultConfig.cancelTranslocoLabel) |
    transloco }}
  </button>
  <button mat-raised-button
          color="primary"
          class="rounded-3xl"
          (click)="close()">
    <span class="px-4">
            {{ (data?.confirmTranslocoLabel ?? defaultConfig.confirmTranslocoLabel) |
            transloco }}</span>
  </button>
</div>