import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { MeetingCategoriesActions } from '@meeting/store/actions/meeting-category.actions';
import { AdminMeetingTemplatePageActions } from '@meeting/store/actions/meeting-template.actions';
import { MeetingTypesActions } from '@meeting/store/actions/meeting-type.actions';
import {
  loadEndedMeetings,
  loadIdleMeetings,
  loadStartedMeetings,
} from '@meeting/store/actions/meeting.actions';
import { User } from '@models/user.model';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _ENDPOINT_URL = `${environment.API_URL}/core/users/me`;

  private _isAuthenticated: boolean;

  currentUserId = null;

  private _currentUser$$: BehaviorSubject<User | undefined | null> =
    new BehaviorSubject<User | undefined | null>(undefined);
  readonly currentUser$ = this._currentUser$$.asObservable();

  get currentUser() {
    return this._currentUser$$.value;
  }

  constructor(
    private readonly jwtHelper: JwtHelperService,
    private readonly _httpClient: HttpClient,
    private readonly _router: Router,
    private readonly _store: Store,
  ) {}

  init() {
    this.getUserFromBearerToken$()
      .pipe(
        take(1),
        switchMap((user) => {
          if (!user) {
            this._currentUser$$.next(null);
            this._router.navigateByUrl('/404');
          }
          return of(user);
        }),
      )
      .subscribe((user) => {
        this._currentUser$$.next(user);
        this._isAuthenticated = !!user;
        if (!user) return;
        this._store.dispatch(loadIdleMeetings());
        this._store.dispatch(loadEndedMeetings());
        this._store.dispatch(loadStartedMeetings());
        this._store.dispatch(MeetingTypesActions.loadMeetingTypes()),
          this._store.dispatch(
            AdminMeetingTemplatePageActions.loadMeetingTemplate(),
          ),
          this._store.dispatch(
            MeetingCategoriesActions.loadMeetingCategories(),
          );
      });
  }

  isLoggedIn(): Observable<boolean> {
    const token = this.jwtHelper.tokenGetter();

    if (!token) {
      return of(false);
    }

    const tokenExpired = this.jwtHelper.isTokenExpired(token);

    if (tokenExpired) {
      return of(false);
    } else {
      if (this._isAuthenticated) {
        return of(true);
      } else {
        this.init();
        return of(true);
      }
    }
  }

  logout() {
    window.location.href = environment.MY_SILAE_URL;
    localStorage.clear();
  }

  getToken(): string | undefined {
    return this.jwtHelper.tokenGetter();
  }

  /**
   * Uses embedded bearer token from jwtModule to get related Talent user
   * @returns User | undefined
   */
  getUserFromBearerToken$(): Observable<User | undefined> {
    return this._httpClient.get<User>(`${this._ENDPOINT_URL}`);
  }

  decodeToken(token: string) {
    return this.jwtHelper.decodeToken(token);
  }

  isTokenExpired(token) {
    return this.jwtHelper.isTokenExpired(token);
  }

  storeToken(token: string): void {
    localStorage.setItem('token', token);
  }

  storeTokenAndConnectionMethod(token: string): void {
    localStorage.setItem('lastConnectionMethod', this.decodeToken(token).auth);
    localStorage.setItem('token', token);
  }

  deleteToken(): void {
    localStorage.removeItem('token');
  }
}
