import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducerMap, MetaReducer } from '@ngrx/store';

export interface AppState {
  router: RouterReducerState;
}

const clearState = (reducer) => {
  return (state: AppState | undefined, action: Action) => {
    return reducer(state, action);
  };
};

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
};

export const metaReducers: MetaReducer<AppState>[] = [clearState];
