import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export enum ERouting {
  PERSONNAL = 'PERSONNAL',
  REVIEWER = 'REVIEWER',
  ADMIN = 'ADMIN',
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  headerLabel$$ = new BehaviorSubject<string | undefined>(undefined);
  isMobile$ = this._breakpointObs
    .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
    .pipe(
      map((result) => {
        return !!result.matches;
      }),
    );

  currentRouting$$ = new BehaviorSubject<ERouting | undefined>(undefined);
  previousCampaignTab$$ = new BehaviorSubject<
    'idle' | 'started' | 'ended' | undefined
  >(undefined);

  constructor(
    public store: Store,
    private _breakpointObs: BreakpointObserver,
  ) {
    this._breakpointObs.observe(Breakpoints.Small);
  }
}
