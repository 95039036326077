import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationModalComponent,
  IConfirmationModalConfig,
} from '@shared/modals/confirmation/confirmation.modal';
import {
  IPendingChangesModalConfig,
  PendingChangesModalComponent,
} from '@shared/modals/pending-changes/pending-changes.modal';

export interface IBaseModalConfig {
  size?: TModalSize;
}

export type TModalSize = '448px' | '684px' | '920px';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private readonly _defaultModalSize: TModalSize = '684px';

  constructor(private readonly _dialog: MatDialog) {}

  openPendingChangesModal$(config?: IPendingChangesModalConfig) {
    return this._dialog
      .open(PendingChangesModalComponent, {
        width: config?.size ?? this._defaultModalSize,
        data: config,
        panelClass: 'no-padding',
      })
      .afterClosed();
  }

  openConfirmationModal$(config?: IConfirmationModalConfig) {
    return this._dialog
      .open(ConfirmationModalComponent, {
        width: config?.size ?? this._defaultModalSize,
        data: config,
        panelClass: 'no-padding',
      })
      .afterClosed();
  }
}
