import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@core/services/notifications.service';
import { environment } from '@env/environment';
import { FileType } from '@models/exports.model';
import { map } from 'rxjs/operators';
import { MIMETYPES } from 'src/app/modules/exports/configs/exports.configs';
import { IPostHogEventConfig } from './posthog.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private _BACKEND_URL = environment.API_URL;

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _notificationService: NotificationService,
  ) {}

  downloadFile(
    endpoint: string,
    fileType: FileType,
    forcedFileName?: string,
    posthogEventConfig?: IPostHogEventConfig,
  ): void {
    let eventId;
    this._notificationService.showNotifForEvent(eventId, {
      message: 'STATUS.CREATION_IN_PROGRESS',
      translateValue: {},
      title: null,
      disableTimeout: true,
      additionalMessage: 'STATUS.CREATION_IN_PROGRESS_DETAILED',
    });
    this._httpClient
      .get(`${this._BACKEND_URL}/${endpoint}`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        map((res) => {
          const contentDisposition = res.headers.get('content-disposition');
          let fileName = forcedFileName ?? `file.${fileType}`;

          if (contentDisposition && !forcedFileName) {
            fileName =
              this._getFileNameFromContentDisposition(contentDisposition);
          }

          return {
            blob: new Blob([res.body], {
              type: MIMETYPES[fileType],
            }),
            fileName,
          };
        }),
      )
      .subscribe(
        (downloadableFile) => {
          this._notificationService.hideNotifForEvent(eventId);
          this._notificationService.showDownload(
            eventId,
            downloadableFile.blob,
            downloadableFile.fileName,
          );
          if (posthogEventConfig) {
            posthogEventConfig.event.next(posthogEventConfig.payload);
          }
        },
        (_err) => {
          this._notificationService.hideNotifForEvent(eventId);
          this._notificationService.showError('ALERTS.E_FILE_ERROR');
        },
      );
  }

  private _getFileNameFromContentDisposition(
    contentDisposition: string,
  ): string {
    let fileName = contentDisposition
      .split(';')
      ?.find((part) => part.trim().startsWith('filename='))
      ?.split('=')[1]
      ?.trim();

    if (fileName?.startsWith('"') && fileName?.endsWith('"')) {
      fileName = fileName.slice(1, -1);
    }
    return fileName;
  }
}
