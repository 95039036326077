import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EDataMutation, PostHogService } from '@core/services/posthog.service';
import { showError, showSuccess } from '@core/store/actions/toast.actions';
import { MeetingTemplateService } from '@meeting/services/meeting-template.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import {
  AdminMeetingTemplatePageActions,
  MeetingTemplatesAPIActions,
  MeetingTemplatesErrorActions,
} from '../actions/meeting-template.actions';

@Injectable()
export class MeetingTemplateEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly meetingTemplateService: MeetingTemplateService,
    private readonly store: Store,
    private readonly postHogService: PostHogService,
    public dialog: MatDialog,
  ) {}

  loadTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminMeetingTemplatePageActions.loadMeetingTemplate),
      exhaustMap((_) =>
        this.meetingTemplateService.getAll().pipe(
          map((templates) =>
            MeetingTemplatesAPIActions.loadMeetingTemplatesSuccess({
              templates,
            }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(
              MeetingTemplatesErrorActions.errorQuestion({
                error,
              }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  addTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminMeetingTemplatePageActions.addMeetingTemplate),
      exhaustMap(({ template: createTemplate }) => {
        return this.meetingTemplateService.create({ ...createTemplate }).pipe(
          map((template) => {
            this.store.dispatch(
              showSuccess({
                successMessage: 'MEETING.ADD_TEMPLATE_SUCCESS',
              }),
            );

            this.postHogService.emitTemplateEvent$$.next({
              mutation: EDataMutation.CREATED,
              template,
            });

            return MeetingTemplatesAPIActions.addMeetingTemplateSuccess({
              template,
            });
          }),
          catchError((error: Error) => {
            this.store.dispatch(
              MeetingTemplatesErrorActions.errorQuestion({
                error,
              }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        );
      }),
    ),
  );

  patchTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminMeetingTemplatePageActions.patchMeetingTemplate),
      exhaustMap(({ template: patchTemplate, templateId }) => {
        return this.meetingTemplateService
          .patch(templateId, patchTemplate)
          .pipe(
            map((template) => {
              this.store.dispatch(
                showSuccess({
                  successMessage: 'MEETING.UPDATE_TEMPLATE_SUCCESS',
                }),
              );

              this.postHogService.emitTemplateEvent$$.next({
                mutation: EDataMutation.MODIFIED,
                template,
              });

              return MeetingTemplatesAPIActions.patchMeetingTemplateSuccess({
                template,
              });
            }),
            catchError((error: Error) => {
              this.store.dispatch(
                MeetingTemplatesErrorActions.errorQuestion({
                  error,
                }),
              );
              return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
            }),
          );
      }),
    ),
  );

  deleteTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminMeetingTemplatePageActions.deleteMeetingTemplate),
      exhaustMap(({ id }) =>
        this.meetingTemplateService.delete(id).pipe(
          map((template) => {
            this.store.dispatch(
              showSuccess({
                successMessage: 'MEETING.DELETE_TEMPLATE_SUCCESS',
              }),
            );

            this.postHogService.emitTemplateEvent$$.next({
              mutation: EDataMutation.DELETED,
              template,
            });

            return MeetingTemplatesAPIActions.deleteMeetingTemplateSuccess({
              id,
            });
          }),
          tap((_) => this.dialog.closeAll()),
          catchError((error: Error) => {
            this.store.dispatch(
              MeetingTemplatesErrorActions.errorQuestion({
                error,
              }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
}
