import {
  animate,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

const easeInOut = 'cubic-bezier(0.24, 0.06, 0.08, 1)';

export const preventInitialChildAnimations = trigger(
  'preventInitialChildAnimations',
  [transition(':enter', [query(':enter', [], { optional: true })])],
);

// FADE IN
export const fadeIn = trigger('fadeIn', [
  // route 'enter' transition
  transition(':enter', [
    // styles at start of transition
    style({ opacity: 0 }),
    // animation and styles at end of transition
    animate('.3s', style({ opacity: 1 })),
  ]),
]);

// FADE IN / OUT
export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.3s', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('.2s', style({ opacity: 0 })),
  ]),
]);

// FADE IN / OUT with void state
export const fadeInOutVoid = trigger('fadeInOutVoid', [
  transition('void => *', [
    style({ opacity: 0 }),
    animate('.3s', style({ opacity: 1 })),
  ]),
  transition('* => void', [
    style({ opacity: 1 }),
    animate('.4s', style({ opacity: 0 })),
  ]),
]);

// EXPAND COLLAPSE
export const openClose = trigger('openClose', [
  transition(':enter', [
    style({ opacity: 0, height: 0 }),
    animate(`.3s ${easeInOut}`, style({ height: '*' })),
    animate('.1s ease-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ height: '*' }),
    group([
      animate('.1s ease-out', style({ opacity: 0 })),
      animate(`.3s ${easeInOut}`, style({ height: 0 })),
    ]),
  ]),
]);

export const filterExpand = trigger('filterExpand', [
  transition(':enter', [
    style({ opacity: 0, height: 0 }),
    animate(`.1s ${easeInOut}`, style({ height: '50px' })),
    animate('.1s ease-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ height: '50px' }),
    group([
      animate('.0s ease-out', style({ opacity: 0 })),
      animate(`.0s ${easeInOut}`, style({ height: 0 })),
    ]),
  ]),
]);

// EXPAND COLLAPSE (paragraph <--> one line)
export const showMore = trigger('showMore', [
  state(
    'preview',
    style({
      'white-space': 'nowrap',
      'text-overflow': 'ellipsis',
      overflow: 'hidden',
      height: '24px',
    }),
  ),
  state(
    'full',
    style({
      // 'white-space': 'initial',
      // 'text-overflow': 'ellipsis',
      overflow: 'hidden',
      height: '*',
    }),
  ),
  transition('preview => full', [animate(`.3s ${easeInOut}`)]),
  transition('full => preview', [animate(`.3s ${easeInOut}`)]),
]);

// EXPAND COLLAPSE
export const accordion = trigger('accordion', [
  transition(':enter', [
    style({
      opacity: 0,
      height: 0,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    animate(
      `.3s ${easeInOut}`,
      style({
        height: '*',
        marginTop: '*',
        marginBottom: '*',
        paddingTop: '*',
        paddingBottom: '*',
      }),
    ),
    animate('.1s ease-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({
      height: '*',
      marginTop: '*',
      marginBottom: '*',
      paddingTop: '*',
      paddingBottom: '*',
    }),
    group([
      animate('.1s ease-out', style({ opacity: 0 })),
      animate(
        `.3s ${easeInOut}`,
        style({
          height: 0,
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }),
      ),
    ]),
  ]),
]);
